<template>
    <front-layout>
        <div class="page__top-line d-flex flex-column justify-center align-center fill-height pa-5 pb-16">
            <v-card flat min-width="300" max-width="500">
                <v-card-title>Login</v-card-title>
                <v-form>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-text-field :error-messages="errors.email" label="Email" v-model="email"/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field :error-messages="errors.password"  type="password" label="Password" v-model="password"/>
                            </v-col>
                        </v-row>
                        <!--<v-row>
                            <v-col>
                                <login-with-github />
                            </v-col>
                            <v-col>
                                <login-with-google />
                            </v-col>
                        </v-row>-->
                    </v-container>
                </v-form>
                <v-card-actions class="text-center justify-space-around">
                    <div>
                        <div>
                            <v-col>
                                <v-btn block class="success" @click="handleLogin">Login</v-btn>
                            </v-col>
                            <v-col>
                                <v-btn block>
                                    <v-icon left >mdi-google</v-icon>
                                    <a href="/social/redirect/google">Google</a>
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-btn block>
                                    <v-icon left >mdi-facebook</v-icon>
                                    <a href="/social/redirect/facebook">Facebook</a>
                                </v-btn>
                            </v-col>
                        </div>
                        <div class="mt-8">
                            <v-btn x-small text :to="{name: 'forgot-password'}">Forgot Password?</v-btn>
                            <v-btn x-small text :to="{name: 'sign-up'}">Sign Up</v-btn>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </div>
    </front-layout>
</template>

<script>
    /*    import LoginWithGithub from "../components/LoginWithGithub";
        import LoginWithGoogle from "../components/LoginWithGoogle";*/
    import FrontLayout from "../layouts/FrontLayout";

    export default {
        name: "Login",
        components: {FrontLayout},
        /*        components: {LoginWithGithub, LoginWithGoogle},*/
        data: function () {
            return {
                email: '',
                password: '',
                errors: {},
            }
        },
        methods: {
            async handleLogin() {
                const {email, password} = this;
                this.$auth
                    .login({data: {email, password}, redirect: null})
                    .then(res => this.$router.push(this.$loginRedirectRoute(res.data.data.roles)))
                    .catch((error) => this.errors = error.response.data.errors)
            },
        }
    }
</script>

<style scoped lang="scss">

</style>
